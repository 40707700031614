import { createTheme, ThemeProvider } from '@mui/material';
import { useWindowWidth } from '@react-hook/window-size';
import cn from 'classnames';
import dynamic from 'next/dynamic';
import Head from 'next/head';
// import Image from 'next/image';
import React, { useEffect, useState } from 'react';
import { InView } from 'react-intersection-observer';
import { useSelector } from 'react-redux';
import { ReviewItem } from '../components/Lists/ReviewItem';
import { RecommendationItem } from '../components/RecommendationsList/RecommendationItem/RecommendationItem';
import { withLayout } from '../Layout/Layout';
import '../styles/utils.module.scss';
import { httpGet } from '../utils/httpClient';
import styles from './index.module.scss';
import { DestinationItem, SearchBox, VintageButton } from '../components';
import { useRouter } from 'next/router';
import { FeaturedFlightsMainPage } from '../components/FeaturedFlightsMainPage/FeaturedFlightsMainPage';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box'
import { DEALS_COUNTRIES } from '../config';

// const News = dynamic(() => import('../components/News/News'));
const CallToSignUp = dynamic(() => import('../components/CallToSignUp/CallToSignUp'), {
  loading: () => <div>Loading Sign Up...</div>
});

const FeaturedContent = dynamic(() => import('../components/FeaturedContent/FeaturedContent'), {
  loading: () => <div>Loading Featured Content...</div>
});

const DealsPage = dynamic(() => import('../components/DealsPage/Dealspage'), {
  loading: () => <Box sx={{ width: '100%', backgroundColor: 'black' }}>
  <LinearProgress color="secondary" />
</Box>
});
const theme = createTheme({
  palette: {
    primary: {
      main: '#ffffff',
    },
    secondary: {
      main: '#0a0a0a',
    },
  },
  typography: {
    fontFamily: '"Libre Franklin", sans-serif',
  },
});

export const getStaticProps = async ({ locale }) => {
  const [featuredRes, destinationsRes] = await Promise.all([
    httpGet(`${process.env.javaApiUrl}/article?location=featured`),
    httpGet(`${process.env.javaApiUrl}/article?location=destination&size=3`)
  ]);

  if (featuredRes.status >= 400 || destinationsRes.status >= 400) {
    return {
      notFound: true,
      revalidate: 60,
    };
  }
  const featured = await featuredRes.json();
  const destinations = await destinationsRes.json();
  return {
    props: {
      featured,
      destinations,
      ...(await serverSideTranslations(locale, ['common'])),
    },
    revalidate: 60,
  };
};

const getFeaturedItems = async (paging) => {
  const response = await httpGet(
    `/api/article?location=featured&page=${paging.page}&size=${paging.pageSize}`
  );
  return await response.json();
};
const getVideo = async () => {
  const videoRes = await httpGet(`/api/video`);

  return await videoRes.json();
};

const getVideoMobile = async () => {
  const videoRes = await httpGet(`/api/video/mobile`);

  return await videoRes.json();
};
const getFeaturedFlights = async (country, origin) => {
  const featuredFlightsDealsRes = await httpGet(
    `/api/flight/deals?origin=${origin}&country=${country}&cabinClass=BIZ&size=6`
  );

  return await featuredFlightsDealsRes.json();
};
const getFeaturedOrigins = async (country) => {
  const featuredFlightsDealsRes = await httpGet(
    `/api/flight/popular/origin?country=${country}&cabinClass=BIZ`
  );

  return await featuredFlightsDealsRes.json();
};

const getReviews = async () => {
  const [airlineReviewRes, hotelReviewRes, destinationsListRes] = await Promise.all([
    httpGet(`/api/airline/review?size=1&sort=random`),
    httpGet(`/api/hotel?size=1&sort=random`),
    httpGet(`/api/destinations?size=1&sort=random`),
  ]);

  const airlineReview = await airlineReviewRes.json();
  const hotelReview = await hotelReviewRes.json();
  const destinationsList = await destinationsListRes.json();
  return {
    airlineReview: airlineReview.content[0],
    hotelReview: hotelReview.content[0],
    destinationsList: destinationsList.content[0],
  };
};

function Home({ featured, destinations, authCompleted }) {
  const { t } = useTranslation();
  const router = useRouter();
  const userStore = useSelector((state) => state.user.value);
  // const [windowWidth, setWindowWidth] = useState(0);
  const [video, setVideo] = useState(null);
  const [cookieAccepted, setCookieAccepted] = useState(false);
  const [searchBoxTab, setSearchBoxTab] = useState(0);
  const [featuredOrigin, setFeaturedOrigin] = useState(null);

  const [isVideo, setIsVideo] = useState(false);
  const [airlineReview, setAirlineReview] = useState(null);
  const [featuredFlights, setFeaturedFlights] = useState(null);
  // const [regionalDeals, setRegionalDeals] = useState(null);
  // const [currentRegionIndex, setCurrentRegionIndex] = useState(0);
  const [destinationsList, setDestinationsList] = useState(null);
  const [hotelReview, setHotelReview] = useState(null);
  const [inspiration, setInspiration] = useState(featured.content);
  const [inspirationInfiniteLoop, setInspirationInfiniteLoop] = useState(false);
  const [inspirationPaging, setInspirationPaging] = useState({
    total: featured.totalElements,
    pageSize: featured.pageable.pageSize,
    page: featured.pageable.pageNumber,
  });
  const [scrolledToGlobe, setScrolledToGlobe] = useState(false);
  const [isVideoFetched, setIsVideoFetched] = useState(false);
  const width = useWindowWidth();
  const checkUserCountry = (userCountry) => {
    if (!userCountry) return false;
    return DEALS_COUNTRIES.includes(userCountry);
  };

  useEffect(() => {
    if (!userStore || !userStore.country) return;

    // const withScroll = width + 17;
    // setWindowWidth(withScroll);
    if (checkUserCountry(userStore?.country)) {
      setVideo({image: '/images/night-sky.webp'});
      setIsVideo(false);
      setIsVideoFetched(false);
      return;
    }

    // setVideo(null);

    if (!isVideoFetched || !video) {
      const fetchVideo = width < 1024 ? getVideoMobile : getVideo;
      
      fetchVideo().then((vid) => {
        setVideo(vid);
        if (vid?.image.includes('.mp4')) {
          setIsVideo(true);
        }
        setIsVideoFetched(true);
      });
    }

  }, [userStore?.country]);

  useEffect(() => {
    let cookieState = JSON.parse(localStorage.getItem('bcCookieAccepted'));
    if (cookieState) {
      setCookieAccepted(cookieState);
    } else {
      window.addEventListener('storage', () => {
        cookieState = JSON.parse(localStorage.getItem('bcCookieAccepted'));
        if (cookieState) {
          setCookieAccepted(cookieState);
        }
      });
    }

    getReviews().then((res) => {
      const { airlineReview, hotelReview, destinationsList } = res;
      setAirlineReview(airlineReview);
      setHotelReview(hotelReview);
      setDestinationsList(destinationsList);
    });
  }, []);
  useEffect(() => {
    if (router.query['hotelsearch']) {
      setSearchBoxTab(1);
    }
  }, [router]);
  useEffect(() => {
    if (featuredOrigin?.origin) {
      getFeaturedFlights(userStore.country, featuredOrigin.selectedOrigin).then(
        (res) => {
          setFeaturedFlights(res);
        }
      );
    } else {
      setFeaturedFlights(null);
    }

    // if (featuredOrigin?.origin) {
    //   const filterObj = {
    //     country: userStore.country,
    //     currency: userStore.currency,
    //     origin: featuredOrigin?.selectedOrigin,
    //     cabinClass: 'BIZ',
    //   };

    //   getRegionOffers(filterObj).then((offersRes) => {
    //     if (!offersRes.offers) {
    //       setRegionalDeals({ offersByRegion: null });
    //       return;
    //     }
    //     const offersByRegion = offersRes.offers?.reduce((acc, offer) => {
    //       if (!acc[offer.region]) {
    //         acc[offer.region] = [];
    //       }
    //       acc[offer.region].push(offer);
    //       return acc;
    //     }, {});

    //     for (const region in offersByRegion) {
    //       offersByRegion[region].sort((a, b) => {
    //         return a.price - b.price;
    //       });
    //     }

    //     const sortedRegions = Object.keys(offersByRegion).sort((a, b) => {
    //       return offersByRegion[b].length - offersByRegion[a].length;
    //     });

    //     const sortedRegionOffers = {};
    //     sortedRegions.forEach((region) => {
    //       sortedRegionOffers[region] = offersByRegion[region];
    //     });

    //     const regionOffers = {
    //       ...offersRes,
    //       offersByRegion: sortedRegionOffers,
    //     };
    //     console.log(regionOffers);
    //     setRegionalDeals({ ...regionOffers });
    //   });
    // }
  }, [featuredOrigin]);

  // useEffect(() => {
  //   if (!regionalDeals?.offersByRegion) return;
  //   const interval = setInterval(() => {
  //     setCurrentRegionIndex(
  //       (prevIndex) =>
  //         (prevIndex + 1) % Object.keys(regionalDeals?.offersByRegion).length
  //     );
  //   }, 5000);

  //   return () => clearInterval(interval);
  // }, [regionalDeals]);

  useEffect(() => {
    if (!cookieAccepted) return;
    
    const script = document.createElement('script');
    script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5728937966944779";
    script.async = true;
    script.crossOrigin = "anonymous";
    document.body.appendChild(script);
  
    return () => {
      document.body.removeChild(script);
    };
  }, [cookieAccepted]);

  useEffect(() => {
    if (userStore) {
      getFeaturedOrigins(userStore.country).then((res) => {
        const featuredOrigin = { ...res, selectedOrigin: res.origin?.[0] };
        setFeaturedOrigin(featuredOrigin);
      });
    }
  }, [userStore]);

  useEffect(() => {
    if (inspirationPaging.page <= 3) {
      getFeaturedItems(inspirationPaging)
        .then((res) => {
          if (inspiration[0].title !== res.content[0].title) {
            setInspiration([...inspiration, ...res.content]);
          } else {
            setInspiration([...res.content]);
          }
          if (inspirationPaging.page === 2) {
            setInspirationInfiniteLoop(true);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [inspirationPaging]);

  return (
    <ThemeProvider theme={theme}>
      <Head>
        <link rel="canonical" href={`${process.env.apiUrl}`} />
        {/* {cookieAccepted && (
          <script
            async
            src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5728937966944779"
            crossOrigin="anonymous"
          ></script>
        )} */}
        <meta name="verify-admitad" content="7c7aa268bd" />
      </Head>
      <div className={cn(styles.topSection)}>
        {video && video.image && isVideo && (
          <>
            <video
              className={cn(styles.topBackground, 'vjs-tech')}
              src={process.env.mediaUrl + video.image.replace('/', '')}
              autoPlay
              muted
              loop
              playsInline
            />
            <div className={cn(styles.topSectionText, 'font-fragment')}>
              {t('index.topSection')}
            </div>
          </>
        )}

        {video && video.image && !isVideo && (
          <>
            <img
              // src={process.env.mediaUrl + video.image.replace('/', '')}
              src={checkUserCountry(userStore?.country) ? '/_next/image?url=images%2Fnight-sky.webp&w=2000&q=95' : process.env.mediaUrl + video.image?.replace('/', '')}
              className={cn(styles.topBackground, 'vjs-tech')}
              alt="Top image"
            />
            {/* <Image
              className={cn(styles.topBackground, 'vjs-tech')}
              src={process.env.mediaUrl + video.image.replace('/', '')}
              alt="Top image"
              layout={'fill'}
              objectFit={'cover'}
              objectPosition={'top center'}
            /> */}
            <div className={cn(styles.topSectionText, 'font-fragment')}>
              {t('index.topSection')}
            </div>
          </>
        )}

        <div className={cn(styles.topSectionSearch, 'container mx-auto')}>
          <SearchBox
            type={width && width < 1024 ? 'sideSearch' : 'mainSearch'}
            activeTab={searchBoxTab}
            searchSource={'Main search' + (scrolledToGlobe ? '(after scroll to globe)' : '')}
          />
        </div>

        {!checkUserCountry(userStore?.country) && userStore && !userStore.userid && (
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div
                ref={ref}
                className={cn(
                  styles.callToSignUpContainer,
                  'w-full mx-auto relative'
                )}
              >
                {inView && (
                  <CallToSignUp
                    country={userStore?.country}
                    authCompleted={authCompleted}
                    isCutomBackground={true}
                    sourcePage={'Index page top'}
                  />
                )}
              </div>
            )}
          </InView>
        )}
      </div>

      {checkUserCountry(userStore?.country) && (
        <DealsPage
          // setDealsLoginDialogState={(event) => {console.log(event);}}
          // setLoginDialogState={(event) => {console.log(event)}}
          emitScrolledToGlobe={(isScrolled) => setScrolledToGlobe(isScrolled)}
          authCompleted={authCompleted}>
        </DealsPage>
      )}
  
      <InView triggerOnce>
        {({ inView, ref }) => (
          <div ref={ref} className={cn(styles.featuredContentWrapper)}>
            <div className={cn(styles.featuredContent)}>
              <div className="container">
                {!checkUserCountry(userStore?.country) && featuredFlights?.flights?.length >= 3 &&
                  featuredOrigin?.selectedOrigin && (
                    <FeaturedFlightsMainPage
                      setFeaturedOrigin={setFeaturedOrigin}
                      featuredOrigin={featuredOrigin}
                      featuredFlights={featuredFlights}
                    />
                  )}

                <div className={'px-4 lg:px-0'}>
                  {inView && (
                    <FeaturedContent
                      items={inspiration}
                      enableInfinite={inspirationInfiniteLoop}
                      executePaginate={() =>
                        setInspirationPaging({
                          ...inspirationPaging,
                          page: inspirationPaging.page + 1,
                        })
                      }
                    />
                  )}
                </div>

                <div className={'w-1/2 lg:w-1/4 mx-auto mt-6'}>
                  <VintageButton
                    href="/hotels/toplists"
                    label={t('index.moreFinestSelection')}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </InView>

      <InView triggerOnce>
        {({ inView, ref }) => (
          <div ref={ref} className={cn(styles.content, 'container')}>
            {inView && (
              <div className="container flex flex-col lg:flex-row justify-center lg:mt-6 sm:mt-3 gap-4">
                <div
                  className={cn(
                    styles.recommendationsList,
                    'flex flex-col w-full lg:w-1/2 mr-4 order-2 lg:order-1'
                  )}
                >
                  <div className={styles.sameHeigtContainer}>
                    {destinationsList && <DestinationItem item={destinationsList} />}
                    <div className="flex justify-center items-center mt-6 mb-10">
                      <div className={'w-1/2 mx-auto'}>
                        <VintageButton
                          dark={true}
                          href="/destinations?tab=destinations"
                          label={t('index.moreDestinations')}
                        />
                      </div>
                    </div>
                  </div>

                  {destinations.content.map((destination, index) => {
                    if (width && width < 1024 && index >= 1) {
                      return;
                    }
                    return (
                      <RecommendationItem
                        key={destination.title}
                        item={destination}
                      />
                    );
                  })}
                  <div className="flex justify-center items-center my-6">
                    <div className={'w-1/2 mx-auto'}>
                      <VintageButton
                        dark={true}
                        href="/destinations?tab=articles"
                        label={t('index.moreGuides')}
                      />
                    </div>
                  </div>
                </div>
                <div className="order-1 lg:order-2 flex flex-col w-full lg:w-1/2 mt-8 lg:mt-0 lg:ml-4">
                  <div className={styles.sameHeightContainer}>
                    <div className={styles.review}>
                      {airlineReview && (
                        <ReviewItem
                          type="Airline"
                          review={airlineReview}
                          url={`/airlines/reviews/${airlineReview.textId}`}
                        />
                      )}
                    </div>
                    <div className="flex justify-center items-center mt-6 mb-10">
                      <div className={'w-1/2 mx-auto'}>
                        <VintageButton
                          dark={true}
                          href="/airlines/reviews"
                          label={t('index.moreAirlineReviews')}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={styles.review}>
                    {hotelReview && (
                      <ReviewItem
                        type="Hotel"
                        starRating={true}
                        review={hotelReview}
                        url={`/hotel/review/${hotelReview.textId}`}
                      />
                    )}
                  </div>
                  <div className="flex justify-center items-center my-6">
                    <div className={'w-1/2 mx-auto'}>
                      <VintageButton
                        dark={true}
                        href="/hotels/reviews"
                        label={t('index.moreHotelReviews')}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </InView>

      {userStore && !userStore.userid && (
        <InView triggerOnce>
          {({ inView, ref }) => (
            <div ref={ref} className="w-full mx-auto">
              {inView && (
                <CallToSignUp
                  country={userStore?.country}
                  authCompleted={authCompleted}
                  sourcePage={'Index page bottom'}
                />
              )}
            </div>
          )}
        </InView>
      )}
      {/* <InView triggerOnce>
        {({ inView, ref }) => (
          <div className={'bg-brown-background w-full pb-10'} ref={ref}>
            <div className="container">{inView && <News />}</div>
          </div>
        )}
      </InView> */}
    </ThemeProvider>
  );
};

export default withLayout(Home, true, true);
